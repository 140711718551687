import React from 'react';
import { Link } from 'gatsby';

class Footer extends React.Component {
  getNavExternalLink = item => (
    <Link
      to={`${item.link.toLowerCase()}`}
      onClick={this.closeMobileMenu}
      activeClassName="active"
      partiallyActive={item && item.partiallyActive ? true : false}
    >
      {item.name}
    </Link>
  );

  getNavList = navItems => (
    <ul id="menu-main" className="menu1">
      {navItems.map(navItem => {
        if (!navItem.mobile)
          return (
            <li key={navItem.name} className="reseted-li">
              {this.getNavExternalLink(navItem)}
            </li>
          );
        return null;
      })}
    </ul>
  );

  render() {
    const { navItems } = this.props;
    return (
      <footer className="site-footer">
        <hr className="footer-hr"></hr>
        <div className="footer_inner">
          <div className="container">
            <div className="footer-widget footer-content">
              <section id="nav_menu-8" className="widget widget_nav_menu">
                <div className="menu-main-container footer-bottom">
                  {this.getNavList(navItems)}
                </div>
              </section>
            </div>
            <div className="site-info">
              ©{new Date().getFullYear()} Creative Clowns. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
