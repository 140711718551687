import React from 'react';
// import facebookicon from '@images/faceicon.png';
import instagramicon from '@images/instaicon.png';

const SocialLinks = () => (
  <section id="joinJourneyBox">
    <h2>Join our journey!</h2>
    <div className="sideBySide">
      <div className="justifiedCenter">
        {/* <a
          className="socialMediaIcon"
          href="https://www.facebook.com/creativeclowns"
        >
          <img src={facebookicon} alt="facebook icon" />
          <h6>/creativeclowns</h6>
        </a> */}
      </div>
      <div className="justifiedCenter">
        <a
          className="socialMediaIcon"
          href="https://www.instagram.com/creativeclowns"
        >
          <img src={instagramicon} alt="instagram icon" />
          <h6>@creativeclowns</h6>
        </a>
      </div>
    </div>
  </section>
);

export default SocialLinks;
