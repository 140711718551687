import { Link } from 'gatsby';
import React from 'react';
import { Squeeze } from 'react-burgers';

import logo from '@images/CreativeClowns-logo.png';
import SocialLinks from '@components/SocialLinks';

const Mobile = props => <div className="mobile">{props.children}</div>;

const MobileMenu = props => (
  <nav className="mobileNavigation">{props.children}</nav>
);

class Header extends React.Component {
  state = {
    mobileMenuOpen: false,
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
    if (!this.state.mobileMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  closeMobileMenu = () => {
    if (this.state.mobileMenuOpen) {
      this.setState({ mobileMenuOpen: false });
      document.body.classList.remove('no-scroll');
    }
  };

  getNavExternalLink = item => (
    <Link
      to={`${item.link.toLowerCase()}`}
      onClick={this.closeMobileMenu}
      activeClassName="active"
      partiallyActive={item && item.partiallyActive ? true : false}
    >
      {item.name}
    </Link>
  );

  getNavList = navItems => (
    <ul>
      {navItems.map(navItem => {
        if (navItem.mobile) {
          return (
            <li className="mobile" key={navItem.name}>
              {this.getNavExternalLink(navItem)}
            </li>
          );
        } else {
          return <li key={navItem.name}>{this.getNavExternalLink(navItem)}</li>;
        }
      })}
    </ul>
  );

  render() {
    const { navItems } = this.props;
    return (
      <header className="site-header">
        <div className="container">
          <div className="row">
            <div className="col-6 col-sm-6 col-md-4 align-self-center">
              <Link className="header-logo" to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="col-6 col-sm-6 col-md-8 align-self-center">
              <nav className="navigation">{this.getNavList(navItems)}</nav>
              <Mobile>
                <Squeeze
                  active={this.state.mobileMenuOpen}
                  onClick={this.toggleMobileMenu}
                  width={18}
                  lineHeight={2}
                  lineSpacing={3}
                  color="#2A2A2A"
                  className="mobileMenuButton"
                  customProps={{
                    'aria-label': 'Mobile menu button',
                  }}
                />
              </Mobile>
            </div>
          </div>
        </div>
        {this.state.mobileMenuOpen ? (
          <MobileMenu>
            {this.getNavList(navItems)}
            <SocialLinks />
          </MobileMenu>
        ) : null}
      </header>
    );
  }
}

export default Header;
